'use strict';

angular.module('enervexSalesappApp').controller('AccountMembershipRequestCtrl', function($scope, $state, $stateParams, Auth, User, Account, AccountMembershipRequest, AccountMembershipRequestService, Util, FileUploader, _) {
	var getCurrentUser = Auth.getCurrentUser;
	$scope.isAdmin = Auth.isAdmin;

	$scope.roles = ["member", "owner"]
	$scope.accountId = $stateParams.accountId;
	$scope.accountMembershipRequestId = $stateParams.accountMembershipRequestId;
	$scope.newComment = {
		notifyMembers:[]
	};
	$scope.ctx = {
		formChanged:false,
		accountType: "commercial"
	}
	$scope.commentOptions = {
		target: "Account Request",
		hideWho: true
	}
	Util.setNotifyMembersType($scope.newComment, $scope.job)
	$scope.accountMembershipRequest = {
		role: 'member'
	};
	// var uploader = $scope.uploader = new FileUploader({});

	if ($scope.accountMembershipRequestId !== 'new'){
		$scope.accountMembershipRequest = AccountMembershipRequest.get({
			id: $stateParams.accountMembershipRequestId
		});
		$scope.accountMembershipRequestComments = AccountMembershipRequestService.getComments();
	}
	$scope.submitRequest = function() {
		AccountMembershipRequest.save({}, {
			account: $scope.accountMembershipRequest.account._id,
			role: $scope.accountMembershipRequest.role,
		}).$promise.then(function(res){
			console.log(res);
			$state.go("accounts", {
				inherit: false,
				reload: true
			})
		});
	};

	function fetchComments(){
		$scope.accountMembershipRequestComments = AccountMembershipRequestService.getComments();
	}
	$scope.submitComment = function(newComment) {
		if (newComment.notifyMembersType === 'all'){
			newComment.notifyMembers = _.map($scope.job.members, function(member){
				return member.user._id;
			});
		} else if (newComment.notifyMembersType === 'some' ) {
			newComment.notifyMembers = $scope.checkedEmailMembers;
		} else if (newComment.notifyMembersType === 'none'){
			newComment.notifyMembers = [];
		}
		if ($scope.uploader && $scope.uploader.queue.length > 0) {
			var promises = [];
			_.each($scope.uploader.queue, function(data){
				var fd = new FormData();
				fd.append('name', data._file.name);
				fd.append('asset', data._file);
				fd.append('visibility', $scope.design.visibility);
				promises.push(DesignService.createAttachment(fd).$promise)
			});
			$q.all(promises).then(function(responses){
				newComment.attachments = responses;
				AccountMembershipRequestService.makeComment(newComment).$promise.then(function(res) {
					$scope.newComment = {
						notifyMembers:[]
					};
					Util.setNotifyMembersType($scope.newComment, $scope.job)
					if ($scope.uploader){
						$scope.uploader.queue = [];
					}
					fetchComments();
				});
			})
		} else {
			AccountMembershipRequestService.makeComment(newComment).$promise.then(function(res) {
				if ($scope.uploader){
					$scope.uploader.queue = [];
				}
				$scope.newComment = {
					notifyMembers:[]
				};
				Util.setNotifyMembersType($scope.newComment, $scope.job)
				fetchComments();
			});
		}
	}
	function toText(account){
		if (account.accountType == "commercial") {
			return (account.prefixCode) ? account.prefixCode + " - " + account.name : account.name
		} else {
			var result =- (account.prefixCode) ? account.prefixCode + " - " + account.name : account.name
			if (account.primaryLocation) {
				var address = account.primaryLocation.address
				if (address) {
					var state = address.state && address.state.stateab
					result = result + " | " + address.address1 + ", " + address.city + " " + address.state.stateab
				}
			}
			return result
		}
	}
	$scope.autoCompleteOptions = {
		minimumChars: 1,
		dropdownWidth: "500px",
		data: function (term) {
			return Account.query({
				limit:20,
				term: term,
				accountType: $scope.ctx.accountType,
				all: true
			}).$promise.then(function(accounts){
				$scope.accountsPage = accounts;
				return _.map(accounts, function(account){
					return toText(account)
				});
			})
		},
		itemSelected: function(item){
			try {
				var account = _.find($scope.accountsPage, function(account){
					return toText(account) == item.item
				})
				$scope.ctx.formChanged = true;
				$scope.accountMembershipRequest.account = account;
			}catch(e){
				console.log(e)
			}
		}
	}
	$scope.saveCommentChange = function(comment){
		AccountMembershipRequestService.updateComment(comment).$promise.then(function(res){
			fetchComments();
		});
	}
	$scope.approveRequest = function() {
		AccountMembershipRequestService.approveRequest().$promise.then(function(){
			$state.go('accounts', {
			}, {
				reload: true,
				inherit: false
			})
		});
	};
	$scope.denyRequest = function() {
		AccountMembershipRequestService.denyRequest().$promise.then(function(){
			$state.go('accounts', {
			}, {
				reload: true,
				inherit: false
			})
		});
	};
	// Not Implemented in Design/Mocks - holding off on html implementation
	$scope.deleteComment = function(comment){
		AccountMembershipRequestService.removeComment(comment).$promise.then(function(){
			fetchComments();
		});
	};
});
